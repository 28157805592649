import { LeaderboardsIcon, DashboardIcon, JoystickIcon, StakeIcon } from 'assets/icons/menuIcons';
import { PATHS } from 'constants/paths';

export const MENU_ITEMS = [
    {
        title: 'Dashboard',
        path: PATHS.DASHBOARD,
        icon: <DashboardIcon />
    },
    {
        title: 'Leaderboard',
        path: PATHS.LEADERBOARD,
        icon: <LeaderboardsIcon />
    },
    {
        title: 'Stake WETH',
        path: PATHS.STAKE_ETH,
        icon: <StakeIcon />,
        comingSoon: true
    },
    {
        title: 'Stake USDB',
        path: PATHS.STAKE_BLAST,
        icon: <StakeIcon />,
        comingSoon: true
    },
    {
        title: 'Play',
        path: PATHS.PLAY,
        icon: <JoystickIcon />,
        comingSoon: true
    }
];
